<template>
  <div class="d-flex justify-content-between">
    <div class="pagination-container">
      <b-pagination
        :total-rows="total"
        :per-page="perPage"
        v-model="currentPage"
        last-number
        :align="breakPoint"
        class="pagination"
      >
      </b-pagination>
      <b-form-select
        class="number-select"
        v-model="selected"
        :options="options"
        @input="pagePerChange"
      ></b-form-select>
    </div>
    <span class="total-items">Total items: {{ total }}</span>
  </div>
</template>

<script>
import { BPagination, BFormSelect } from "bootstrap-vue";

export default {
  data() {
    return {
      options: [10, 20, 50],
      selected: 10,
    };
  },
  name: "XmlTvPagination",
  components: {
    BPagination,
    BFormSelect,
  },
  props: {
    metaData: Object,
  },
  computed: {
    total() {
      return this.metaData?.total ?? 0;
    },
    perPage() {
      return this.metaData?.per_page ?? 10;
    },
    currentPage: {
      get() {
        return this.metaData?.current_page ?? 0;
      },
      set(page) {
        this.$emit("change-page", page);
      },
    },
    breakPoint() {
      return this.$store.getters["app/currentBreakPoint"] === "xs"
        ? "fill"
        : "default";
    },
  },
  mounted() {
    this.currentPage = this.metaData?.current_page;
  },
  methods: {
    pagePerChange(perPage) {
      this.$emit("change-per-page", perPage);
    },
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
  .pagination {
    width: 100%;
    margin: auto 0;
  }
}
.pagination-container {
  display: flex;
}
.pagination {
  margin: auto 0;
}
.number-select {
  width: auto;
  margin: auto 0 auto 15px;
}
span {
  margin: auto 0 auto 15px;
}
</style>
